import { Switch, BrowserRouter, Route } from "react-router-dom";
import Login from "./Login";
import Faturas from "./Faturas";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/:uc/faturas">
            <Faturas />
          </Route>
          <Route exact path="/">
            <Login />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}
