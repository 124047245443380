import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  container: {
    height: "100vh",
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  content: {
    maxWidth: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    objectFit: "cover",
    width: "min-content",
  },
  formPaper: {
    padding: "4rem 4rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formInput: {
    marginTop: "1rem",
  },
  button: {
    backgroundColor: "#20BFD8",
    marginTop: "1rem",
    transition: "all 2s ease-in",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  link: {
    marginTop: "0.6rem",
  },
});
