import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import { useForm } from "react-hook-form";
import api from "../services/api";

import { useStyles } from "./styles";
// import logo from "../../assets/Icons/logo-default.svg";

interface SignInFormData {
  uc: number;
}

const SignIn: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<SignInFormData>();

  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSubmitForm = useCallback(
    async ({ uc }: SignInFormData) => {
      try {
        setShowError(false);
        setLoading(true);

        const response = await api.get(`agencia/${uc}`);

        setLoading(false);

        if (!response?.data?.uc?.idUc) {
          setShowError(true);
          return;
        }

        history.push(`/${uc}/faturas`);
      } catch (error) {
        setLoading(false);
        setShowError(true);
      }
    },
    [history]
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Paper className={classes.formPaper}>
          <Typography variant="h4" paragraph>
            Fatura fácil e rápida
          </Typography>
          <Typography variant="body1" paragraph>
            Aqui você pode ver a lista de faturas de uma unidade consumidora
            (imóvel) e obter as faturas para pagamento.
          </Typography>
          <Typography variant="body2" paragraph>
            Digite o código da UC (imóvel){" "}
            <strong>sem o dígito verificador</strong>.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Exemplo:</strong> código 12345-9 informe 12345.
          </Typography>
          {showError && (
            <Alert severity="error">
              Código informado não foi encontrado. Por favor, verifique se está
              correto. O código deve ser informado sem o dígito verificador!
            </Alert>
          )}
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <TextField
              name="uc"
              className={classes.formInput}
              variant="outlined"
              required
              disabled={isLoading}
              error={!!errors.uc}
              autoFocus
              placeholder="000000"
              inputRef={register({
                required: true,
                pattern: /[0-9]+/,
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeWorkIcon />
                  </InputAdornment>
                ),
              }}
              helperText={errors.uc && "Informe um código válido"}
              FormHelperTextProps={{
                error: true,
              }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]+" }}
            />
            <Button
              className={classes.button}
              size="large"
              type="submit"
              variant="contained"
              disabled={isLoading}
            >
              Consultar
              {isLoading && (
                <CircularProgress
                  size={23}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default SignIn;
